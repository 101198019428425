import { NgModule } from '@angular/core';
import { ErrorComponent } from './shared/error/error.component';
import { RouterModule, Routes } from '@angular/router';
import { TrackingHistoryWagonComponent } from './shared/components/tracking-history-wagon/tracking-history-wagon.component';

const routes: Routes = [
  { 
    path: '', 
    redirectTo: 'gzp/trainorder/home', 
    pathMatch: 'full' 
  },  
  { 
    path: 'gzp/error/:code', 
    component: ErrorComponent, 
    pathMatch: 'prefix' 
  },
  {
    path: 'gzp/trainorder',
    loadChildren: () => import('./trainorder/trainorder.module').then(m => m.TrainorderModule),
    canActivate: []
  },
  {
    path: 'customer-portal/wagonview',
    loadChildren: () => import('./order-management/order-management.module').then(m => m.OrderManagmentModule),
    canActivate: []
  },
  {
    path: 'customer-portal/example',
    loadChildren: () => import('./order-management/order-management.module').then(m => m.OrderManagmentModule),
    canActivate: []
  },
  {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: []
  },
  {
    path: 'trackingHistoryWagon',
    component: TrackingHistoryWagonComponent,
    canActivate: []
  },
  { 
    path: '**', 
    redirectTo: '/gzp/error/404' 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
